import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import "./TabSettings.css";
import {
  settingsController,
  userSettingsController,
} from "../../controllers/settingsController";
import Loader from "../../components/Loader.tsx";
import Spinner from "../../components/Spinner.tsx";
import axios from "axios";
import { getRootPath } from "../../utils/helper.ts";

const Settings = ({ tabContent }) => {
  const [autoResponderActive, setAutoResponderActive] = useState(false);
  const [autoResponderMessage, setAutoResponderMessage] = useState("");
  const [autoResponderTimes, setAutoResponderTimes] = useState({}); // Store times for each day
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [autoSignature, setAutoSignature] = useState(""); // New state for Auto Signature
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const handleAutoSignatureChange = (e) => {
    setAutoSignature(e.target.value);
  };

  const handleToggle = () => {
    setAutoResponderActive(!autoResponderActive);
  };

  const handleInputChange = (e) => {
    setAutoResponderMessage(e.target.value);
  };

  const handleDayOfWeekChange = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays((prevDays) => prevDays.filter((d) => d !== day));
    } else {
      setSelectedDays((prevDays) => [...prevDays, day]);
    }
  };

  const handleTimeChange = (day, field, value) => {
    setAutoResponderTimes((prevTimes) => ({
      ...prevTimes,
      [day]: {
        ...prevTimes[day],
        [field]: value,
      },
    }));
  };

  const setInitialSignature = async () => {
    const storedUser = localStorage.getItem("user");
    const user = JSON.parse(storedUser);
    setAutoSignature(user.autoSignature);
  };

  useEffect(() => {
    setInitialSignature();

    setDaysOfWeek([
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ]);
    setSelectedDays([
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ]);
  }, []);

  useEffect(() => {
    setLoading(true);
    let organizationId = localStorage.getItem("organizationId") ?? "";
    console.log("Fetching Settings");
    settingsController(
      null,
      "/getSettings/" + organizationId,
      "GET",
      (response) => {
        console.log("dfa");
        console.log(response);
        if (response) {
          console.log(response);
          const autoResponderActiveSetting = response.find(
            (setting) => setting.name === "autoResponderActive"
          );
          const autoResponderMessageSetting = response.find(
            (setting) => setting.name === "autoResponderMessage"
          );
          const autoResponderTimesSetting = response.find(
            (setting) => setting.name === "autoResponderTimes"
          );
          const autoSignatureSetting = response.find(
            (setting) => setting.name === "autoSignature"
          );
          const autoResponderDaysOfWeekSetting = response.find(
            (setting) => setting.name === "autoResponderDaysOfWeek"
          );

          if (autoResponderDaysOfWeekSetting) {
            setSelectedDays(autoResponderDaysOfWeekSetting.value);
          }

          if (autoResponderActiveSetting) {
            setAutoResponderActive(autoResponderActiveSetting.value);
          }

          if (autoResponderMessageSetting) {
            setAutoResponderMessage(autoResponderMessageSetting.value);
          }

          if (autoResponderTimesSetting) {
            setAutoResponderTimes(autoResponderTimesSetting.value);
          }

          if (autoSignatureSetting) {
            setAutoSignature(autoSignatureSetting.value);
          }
        }
        setLoading(false);
      }
    );
  }, []);

  const [isSaved, setIsSaved] = useState(false);

  const [postingSettings, setPostingSettings] = useState(false);
  const handleSaveGlobal = () => {
    setPostingSettings(true);
    let organizationId = localStorage.getItem("organizationId") ?? "";
    const settingsData = [
      {
        orgId: organizationId,
        name: "autoResponderActive",
        value: autoResponderActive,
      },
      {
        orgId: organizationId,
        name: "autoResponderMessage",
        value: autoResponderMessage,
      },
      {
        orgId: organizationId,
        name: "autoResponderDaysOfWeek",
        value: selectedDays,
      },
      {
        orgId: organizationId,
        name: "autoResponderTimes",
        value: autoResponderTimes,
      },
    ];

    settingsController(settingsData, "/save", "POST", (response) => {
      if (response) {
        console.log("Settings successfully saved", response);
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 3000);
        setPostingSettings(false);
      } else {
        console.log("Failed to save settings");
        setPostingSettings(false);
      }
    });
  };

  const [postingSignature, setPostingSignature] = useState(false);

  const token = localStorage.getItem("jwttoken");
  const headers = {
    "x-access-token": token,
    "Content-Type": "application/json",
  };

  const handleSaveUser = async () => {
    setPostingSignature(true);
    try {
      const { data } = await axios.put(
        `${getRootPath()}users`,
        { autoSignature },
        {
          headers,
        }
      );
      const userJson = JSON.stringify(data);
      localStorage.setItem("user", userJson);
    } catch (error) {
      alert("Error saving signature");
    }

    setPostingSignature(false);
  };

  const handleChangeTab = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <div className="settings-container">
      <header>
        <h1>Settings</h1>
      </header>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      ) : (
        <>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            variant="fullWidth"
          >
            <Tab label="Global Settings" />
            <Tab label="User Settings" />
          </Tabs>

          {activeTab === 0 && (
            <Box sx={{ padding: 2 }}>
              <div className="toggle-container">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={autoResponderActive}
                    onChange={handleToggle}
                  />
                  <span className="slider"></span>
                </label>
                <h5>AutoResponder Active</h5>
              </div>

              <div className="textarea-container">
                <textarea
                  className={`autoresponse-textarea mb-3 ${
                    !autoResponderActive ? "disabled" : ""
                  }`}
                  placeholder="Type your autoresponse message here..."
                  value={autoResponderMessage}
                  onChange={handleInputChange}
                  disabled={!autoResponderActive}
                />
                <div className="days-of-week-container">
                  <h5 className="mb-3">Days of the Week AutoResponder is On</h5>
                  <div className="days-checkboxes mb-3">
                    {daysOfWeek.map((day) => (
                      <label key={day}>
                        <input
                          type="checkbox"
                          value={day}
                          checked={selectedDays.includes(day)}
                          onChange={() => handleDayOfWeekChange(day)}
                        />
                        {day}
                      </label>
                    ))}
                  </div>
                </div>
                <div className="time-range-container">
                  <h5 className="mb-3">
                    Autoresponder Off During Time Range <br />
                    (Set this to office hours in EST Time zone)
                  </h5>
                  <table>
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {daysOfWeek
                        .filter((day) => selectedDays.includes(day))
                        .map((day) => (
                          <tr key={day}>
                            <td>{day}</td>
                            <td>
                              <input
                                type="time"
                                value={autoResponderTimes[day]?.start || ""}
                                onChange={(e) =>
                                  handleTimeChange(day, "start", e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="time"
                                value={autoResponderTimes[day]?.end || ""}
                                onChange={(e) =>
                                  handleTimeChange(day, "end", e.target.value)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex justify-end mb-10">
                <button
                  className="save-button"
                  onClick={handleSaveGlobal}
                  disabled={postingSettings}
                >
                  {postingSettings ? <Spinner /> : isSaved ? "Saved" : "Save"}
                </button>
              </div>
            </Box>
          )}
          {activeTab === 1 && (
            <Box sx={{ padding: 2 }}>
              <div className="textarea-container">
                <textarea
                  className="auto-signature-textarea"
                  placeholder="Type your auto signature here..."
                  value={autoSignature}
                  onChange={handleAutoSignatureChange}
                />
              </div>

              <button className="btn btn-primary" onClick={handleSaveUser}>
                {postingSignature ? <Spinner /> : "Save"}
              </button>
            </Box>
          )}
        </>
      )}
    </div>
  );
};
export default Settings;
