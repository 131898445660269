import PeopleIcon from "@mui/icons-material/People";
import MessageIcon from "@mui/icons-material/Message";
import LinkIcon from "@mui/icons-material/Link";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BroadcastIcon from "@mui/icons-material/Campaign";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import EmailIcon from "@mui/icons-material/Email";

export const sidebarContent = [
  {
    id: "S1",
    title: "Messages",
    icon: PeopleIcon,
    subTitle: "Members",
    subIcon: MessageIcon,
  },
  {
    id: "S4",
    title: "New Member",
    icon: PersonAddIcon,
    subTitle: "Add New Member",
  },
  {
    id: "S2",
    title: "Bookmarks",
    icon: BookmarkIcon,
    subTitle: "Quick Links",
    subIcon: LinkIcon,
  },
  {
    id: "S6",
    title: "Broadcast",
    icon: BroadcastIcon,
    subTitle: "Broadcast",
    subIcon: BroadcastIcon,
  },
  {
    id: "S5",
    title: "Settings",
    icon: SettingsIcon,
    subTitle: "Dashboard Settings",
    subIcon: SettingsIcon,
  },
  {
    id: "S7",
    title: "Email",
    icon: EmailIcon,
    subTitle: "Email Notification Settings",
    subIcon: EmailIcon,
  },
  /* { id: "S3", title: "New Message", icon: AddCircleOutlineIcon, subTitle: "Send New Message" },*/
];

export const configuration = {
  // apiPath: "http://localhost:5005",
  apiPath: "https://api.zelohub.com",
  apiEndpoint: "/api/",
  twiloMessageEvent: "twilio-sms",
};
