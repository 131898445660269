import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import "./spinner.css";

type Props = {
};

const Loader: React.FC<Props> = () => {
  return (
    <div className="page-loader">
      <FaSpinner className="animate-spin" />
    </div>
  );
};

export default Loader;
