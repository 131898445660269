import React, { useEffect, useRef, useState } from "react";
import {
  Widget,
  toggleWidget,
  isWidgetOpened,
  setQuickButtons,
  addUserMessage,
  addResponseMessage,
  renderCustomComponent,
  deleteMessages,
  toggleInputDisabled,
  dropMessages,
} from "react-chat-widget"; // Import the chat widget
import "react-chat-widget/lib/styles.css";
import { Patient, usePatientData } from "../zustand/patient.store.ts";
import { usePatientDetail } from "../zustand/patient-details.store.ts";
import { formatAMPM } from "../utils/currentTime";
import "./ChatBox.css";
import logo from "../assets/icon-48.png";
import {
  messagesController,
  sendMMS,
} from "../controllers/messagesController.js";
import { templatesController } from "../controllers/templatesController.js";
import ClickedUserDetail from "../ClickedUserDetails/ClickedUserDetail.tsx";
import MMSFile from "./MMSFile.tsx";
import TemplateDialog from "./TemplateDialog.tsx";
import {
  settingsController,
  userSettingsController,
} from "../controllers/settingsController.js";
import { getRootPath } from "../utils/helper.ts";
import Loader from "../components/Loader.tsx";

interface ChatBoxProps {
  activeUser: Patient;
  showMessage: Boolean;
  setShowMessage: Function;
}

const ChatBox: React.FC<ChatBoxProps> = ({
  activeUser,
  showMessage,
  setShowMessage,
}: ChatBoxProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const bottomEl = useRef(null);
  const [isTemplateDialogOpen, setIsTemplateDialogOpen] = useState(false);
  const [templatesData, setTemplatesData] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);
  const [addedPrivateLink, setAddedPrivateLink] = useState(false);
  const [autoSignature, setAutoSignature] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  // Set Patient Details
  const dispatchPatientDetails = usePatientDetail((state) => state.actions);
  dispatchPatientDetails.setPatientDetail(activeUser);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const userObj = JSON.parse(user);
    if (!userObj) return;
    setAutoSignature(userObj.autoSignature);

    // settingsController(
    //   {
    //     "Content-Type": "application/json",
    //     "x-access-token": jwttoken,
    //   },
    //   "/getSettings/" + organizationId,
    //   "GET",
    //   (response) => {
    //     if (response) {
    //       console.log("aurtoSignature", response);
    //       const autoSignatureSetting = response.find(
    //         (setting) => setting.name === "autoSignature"
    //       );
    //       if (autoSignatureSetting) {
    //         console.log("autoSignatureSetting", autoSignatureSetting);
    //       }
    //     }
    //   }
    // );
  }, []);

  useEffect(() => {
    setQuickButtons([
      { label: "📎 File", value: "Attach-File" },
      { label: "💌 Templates", value: "Use Template" },
    ]); // , {label: "💌 Send Private", value: "Send Private"}]);
    if (!isWidgetOpened()) {
      toggleWidget();
    }
  }, []);

  useEffect(() => {
    // Get the div that you want to scroll
    const messagesDiv = document.getElementById("messages");
    // If the div exists, scroll it to the bottom
    if (messagesDiv) {
      messagesDiv.scrollTop = messagesDiv.scrollHeight;
    }
  }, [messages]);

  const formatDate = (inputDate: string | Date) => {
    const date = new Date(inputDate);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate().toString().padStart(2, "0"); // add leading zero if needed
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0"); // add leading zero if needed

    const amPm = hours < 12 ? "AM" : "PM";
    if (hours > 12) hours -= 12;
    if (hours === 0) hours = 12;

    return `${month} ${day}, ${year}, ${hours}:${minutes} ${amPm}`; // Aug 13, 2023, 5:56 PM
  };

  const renderMessages = (messages) => {
    messages.forEach((message) => {
      let messageText = `${message.message}\n\n${formatDate(message.sentDate)}`;
      // Check if the message is from the user or a response
      if (message.isMMS === false) {
        if (message.from === activeUser.phoneNumber) {
          addResponseMessage(messageText, message._id);
        } else {
          messageText = `${messageText} \n ${message.name}`;
          addUserMessage(messageText, message._id);
        }
      } else {
        const token = localStorage.getItem("jwttoken");
        renderCustomComponent(
          MMSFile,
          { fileUrl: message.mediaPath, fileType: message.filetype, token },
          true
        );
      }
    });
    // Get the div that you want to scroll
    const messagesDiv = document.getElementById("messages");

    // If the div exists, scroll it to the bottom
    if (messagesDiv) {
      setTimeout(() => {
        messagesDiv.scrollTop = messagesDiv.scrollHeight;
      }, 1000);
    }
    const checkbox = document.getElementById(
      "customCheckbox"
    ) as HTMLInputElement;
    if (!checkbox) {
      updateWidgetWithCheckbox();
      updateDisableSendState(false);
    } else {
      updateDisableSendState(checkbox.checked);
    }
  };

  const onStorageChange = (event) => {
    try {
      const lastNew = JSON.parse(localStorage.getItem("lastNew") ?? "");
      const filteredMessages = lastNew.newMessages.filter((message) => {
        return message.from === activeUser.phoneNumber;
      });
      setMessages(filteredMessages);
      renderMessages(filteredMessages);
      // // Check if there's a consent message in the new messages
      for (const message of lastNew.newMessages) {
        if (message.message === "I CONSENT") {
          activeUser.didConsent = true;
          dispatchPatientDetails.setPatientDetail(activeUser);
          break;
        }
      }
    } catch (e) {
      // Handle error
    }
  };

  // const onStorageChange = (changes, area) => {
  //   if (area === "local" && changes.lastNew) {
  //     try {
  //       const lastNew = JSON.parse(localStorage.getItem("lastNew") ?? "");
  //       console.log(activeUser.phoneNumber);
  //       console.log(lastNew.newMessages);
  //       const filteredMessages = lastNew.newMessages.filter((message) => {
  //         console.log("MESSAGE" + message.from);
  //         console.log("USER" + activeUser.phoneNumber);

  //         return message.from === activeUser.phoneNumber;
  //       });

  //       setMessages(filteredMessages);
  //       renderMessages(filteredMessages);

  //       //check if its a consent message
  //       for (const message of lastNew.newMessages) {
  //         if (message.message === "I CONSENT") {
  //           console.log("patient consented");
  //           // User consented, update local state
  //           activeUser.didConsent = true;
  //           dispatchPatientDetails.setPatientDetail(activeUser);
  //           break;
  //         }
  //       }
  //     } catch (e) {
  //       // Handle error
  //       console.log("local storage", e);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   // Add the storage change listener when the component mounts
  //   chrome.storage.onChanged.addListener(onStorageChange);

  //   // Clean up the listener when the component unmounts
  //   return () => {
  //     chrome.storage.onChanged.removeListener(onStorageChange);
  //   };
  // }, [activeUser._id]); // Empty dependency array means this effect runs once when the component mounts

  useEffect(() => {
    setIsLoading(true);
    setIsPrivate(false);
    dropMessages();
    const chatInputDiv = document.querySelector(".rcw-input") as HTMLDivElement;
    if (chatInputDiv) {
      chatInputDiv.innerText = "";
    }
    const checkbox = document.getElementById(
      "customCheckbox"
    ) as HTMLInputElement;
    if (checkbox) {
      checkbox.checked = false;
      const handleCheckboxChange = () => {
        setIsPrivate(checkbox.checked);
        updateDisableSendState(checkbox.checked);
      };
      handleCheckboxChange();
      checkbox.addEventListener("change", handleCheckboxChange);
    } else {
      updateDisableSendState(false);
    }
    const onStorageChangeFn = onStorageChange.bind(this);

    try {
      window.removeEventListener("storage", onStorageChangeFn);
    } catch (error) {
      console.error("Error removing storage listener:", error);
    }
    window.addEventListener("storage", onStorageChangeFn);
    // chrome.storage.onChanged.addListener(onStorageChange);
    // Define endpoint and method for fetching messages
    const endpoint = "/patient-messages";
    const method = "POST";
    const orgId = localStorage.getItem("organizationId") ?? "";
    // Structure the data required for the request
    const data = JSON.stringify({
      phoneNumber: activeUser.phoneNumber,
      _id: activeUser._id, // Add patientId if needed
      patientId: activeUser.patientId,
      orgId: orgId,
    });

    var myHeaders = new Headers();
    const token = localStorage.getItem("jwttoken");

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-access-token", token);
    // Use messagesController to fetch messages

    fetch(`${getRootPath()}sms/patient-messages`, {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((response) => {
        if (Array.isArray(response) && response.length > 0) {
          // Check if response is an array and has at least one message
          setMessages(response);
          // Render messages
          deleteMessages(500);
          renderMessages(response);
          console.log("Messages fetched", response);
        } else {
          // Handle the case where response is an empty array
          updateWidgetWithCheckbox();
          if (response.status == 403) {
            console.log("session expired");
            localStorage.removeItem("token");
            localStorage.removeItem("jwttoken");
            localStorage.removeItem("user");
            localStorage.removeItem("organizationId");
          }
        }
        setIsLoading(false);
      })
      .catch((error) => console.log("error", error));
    return () => {
      // Clean up: Remove the storage event listener when the component unmounts
      window.removeEventListener("storage", onStorageChangeFn);
    };
  }, [activeUser._id]);

  useEffect(() => {
    const checkbox = document.getElementById(
      "customCheckbox"
    ) as HTMLInputElement;
    if (checkbox) {
      const handleCheckboxChange = () => {
        setIsPrivate(checkbox.checked);
        updateDisableSendState(checkbox.checked);
      };

      checkbox.addEventListener("change", handleCheckboxChange);

      // Cleanup
      return () => {
        checkbox.removeEventListener("change", handleCheckboxChange);
      };
    }
  }, [activeUser._id, isPrivate]);

  const updateDisableSendState = (isPrivateValue) => {
    const shouldDisable = !activeUser.didConsent && !isPrivateValue;
    const inputElement = document.querySelector(".rcw-new-message");

    if (inputElement) {
      if (
        shouldDisable &&
        !inputElement.classList.contains("rcw-message-disable")
      ) {
        toggleInputDisabled();
      } else if (
        !shouldDisable &&
        inputElement.classList.contains("rcw-message-disable")
      ) {
        toggleInputDisabled();
      }
    }

    return shouldDisable;
  };

  const handleTemplateSelect = (template) => {
    const checkbox = document.getElementById(
      "customCheckbox"
    ) as HTMLInputElement;
    const isCheckboxChecked = checkbox ? checkbox.checked : false;

    if (!activeUser.didConsent && !isCheckboxChecked) {
      console.log(
        "cannot send template message user did not consent and message is not private"
      );
      return;
    }

    addUserMessage(template.value);
    handleNewUserMessage(template.value);
  };
  const handleConsent = (message) => {
    addUserMessage(message);
    handleNewUserMessage(message, true);
    setMessages(message);
  };
  const handleCloseTemplateDialog = () => {
    setIsTemplateDialogOpen(false);
  };

  const updateWidgetWithCheckbox = () => {
    const checkboxInDom = document.getElementsByClassName("checkbox-container");
    if (!checkboxInDom.length) {
      const textEntryContainer = document.querySelector(".rcw-sender");

      // Create the checkbox element
      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.id = "customCheckbox";
      checkbox.classList.add("custom-checkbox");

      // Create the label element
      const label = document.createElement("label");
      label.setAttribute("for", "customCheckbox");
      label.innerText = "Send as private link";
      label.classList.add("custom-label");

      // Create a new container for checkbox and label
      const checkboxContainer = document.createElement("div");
      checkboxContainer.classList.add("checkbox-container");

      const handleCheckboxChange = () => {
        setIsPrivate(checkbox.checked);
        updateDisableSendState(checkbox.checked);
      };

      checkbox.addEventListener("change", handleCheckboxChange);

      checkboxContainer.appendChild(checkbox);
      checkboxContainer.appendChild(label);

      // Prepend the new container to the text entry container
      if (textEntryContainer) {
        textEntryContainer.prepend(checkboxContainer);
        setAddedPrivateLink(true);
        updateDisableSendState(false);
      } else {
        setTimeout(() => {
          updateWidgetWithCheckbox();
        }, 500);
      }
    }
  };

  const handleSubmit = (newMessage) => {
    try {
      console.log("message", newMessage);
      const message = handleNewUserMessage(newMessage, false);
      setMessages(newMessage);
      console.log("messagess", message);

      const chatInputDiv = document.querySelector(
        ".rcw-input"
      ) as HTMLDivElement;
      if (chatInputDiv) {
        chatInputDiv.innerText = "";
      }
      throw new Error("stop");
      return false;
    } catch (e) {
      console.log(e);
    }
  };

  const handleNewUserMessage = (newMessage, isConsent?) => {
    const checkbox = document.getElementById(
      "customCheckbox"
    ) as HTMLInputElement;
    const isCheckboxChecked = checkbox ? checkbox.checked : false;

    const endpoint = isCheckboxChecked ? "/sendPL" : "/sendSMS";

    if (!activeUser.didConsent && !isCheckboxChecked && !isConsent) {
      console.log(
        "cannot send message user did not consent and message is not private"
      );
      return null;
    }

    console.log("new message =  ", newMessage);
    console.log("isConsent =  ", isConsent);
    if (!isConsent && autoSignature) {
      console.log("has auto signature and is not consent");
      newMessage = `${newMessage}\n\n${autoSignature}`;
      console.log("then new message =  ", newMessage);
    }
    const orgId = localStorage.getItem("organizationId") ?? "";
    const data = JSON.stringify({
      message: newMessage,
      orgId: orgId,
      to: activeUser.phoneNumber,
      patient_id: activeUser._id,
      isMMS: false,
    });
    var myHeaders = new Headers();
    const token = localStorage.getItem("jwttoken") ?? "";

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-access-token", token);
    // Use messagesController to fetch messages

    fetch(`${getRootPath()}sms${endpoint}`, {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((messageSent) => {
        if (messageSent) {
          //success
        } else {
          // error sending.
          console.log(messageSent);
        }
      })
      .catch((error) => console.log("error", error));
    return newMessage;
  };

  const handleQuickButtonClicked = (e) => {
    const message = e;
    console.log(message);

    if (message === "Use Template") {
      setIsTemplateDialogOpen(true); // Open the template dialog
    }
    if (message === "Attach-File") {
      // Create an input element to select files
      const fileInput = document.createElement("input");
      fileInput.type = "file";

      // Add an event listener to handle the selected file
      fileInput.addEventListener("change", (event) => {
        const inputElement = event.target as HTMLInputElement;
        const selectedFile = inputElement.files?.[0];
        if (selectedFile) {
          const checkbox = document.getElementById(
            "customCheckbox"
          ) as HTMLInputElement;
          const endpoint = "/sendPLMMS";

          const orgId = localStorage.getItem("organizationId") ?? "";
          const formData = new FormData();
          formData.append("file", selectedFile);
          formData.append("patient_id", activeUser._id);
          formData.append("orgId", orgId);
          const method = "POST";
          console.log("Selected File:", selectedFile);
          console.log("Active User ID:", activeUser._id);
          for (let [key, value] of formData.entries()) {
            console.log(key, value);
          }

          sendMMS(formData, endpoint, (response) => {
            if (response) {
              console.log("File uploaded successfully", response);
              const token = localStorage.getItem("jwttoken");
              renderCustomComponent(
                MMSFile,
                {
                  fileUrl: response.mediaPath,
                  fileType: selectedFile.type,
                  token,
                },
                true
              );
            } else {
              console.error("Error uploading file");
            }
          });
        }
      });

      // Trigger the file browser menu
      fileInput.click();
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="chatbox-container">
          <div className="chatbox-header">
            {/* Chat Widget */}
            <div className="chat-widget-container">
              <Widget
                title=""
                subtitle=""
                handleSubmit={(e) => handleSubmit(e)}
                handleQuickButtonClicked={(button) =>
                  handleQuickButtonClicked(button)
                }
                showCloseButton={false}
                profileClientAvatar={logo}
              />
            </div>
          </div>
          {
            <ClickedUserDetail
              sendMessage={(newMessage) => handleConsent(newMessage)}
            />
          }
          {isTemplateDialogOpen && (
            <TemplateDialog
              handleTemplateSelect={handleTemplateSelect}
              handleClose={handleCloseTemplateDialog}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ChatBox;
