import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import "./TabNewContact.css";
import NewPatient from "../../Patients/NewPatient.tsx";

type Props = {
  tabContent: { title: string; subTitle: string }[];
  handleCreated: (patient) => void;
};

const TabNewContact: React.FC<Props> = ({ tabContent, handleCreated }) => {
  return (
    <>
      <main className="main-wrapper">
        <NewPatient handleCreated={handleCreated} />
      </main>
    </>
  );
};

export default TabNewContact;
