import { create } from 'zustand';

export interface Patient {
    _id: string;
    patientId?: string
    patientName: string;
    phoneNumber: string;
    homePhoneNumber: string;
    DOB: string;
    tags: string;
    notes: string;
    didConsent: boolean;
    image?: string;
}

let patients: Patient[] = [];

export interface PatientState {
    actions: {
        setPatients: (list: Patient[]) => void;
        setPatient: (id: string, updatedPatient: Partial<Patient>) => void;
        addPatient: (newPatient: Patient) => void;
    };
    patients: Patient[];
}

export const usePatientData = create<PatientState>((set) => ({
    patients: [],
    actions: {
        setPatients: (newpatients: Patient[]) => {
          set(() => ({
            patients: [...newpatients]
          }));
        },      
        setPatient: (id: string, updatedPatient: Partial<Patient>) => {
            set((state) => {
              const updatedPatients = state.patients.map(patient => {
                if (patient._id === id) {
                  return {
                    ...patient,
                    ...updatedPatient
                  };
                }
                return patient;
              });
              return { patients: updatedPatients };
            });
          },
        addPatient: (newPatient) => {
            set((state) => ({ patients: [...state.patients, newPatient] }));
        }
            
    },
}));

