// ClickedUserDetail.js
import React, { useEffect, useState } from "react";
import "./ClickedUserDetail.css";
import { usePatientDetail } from "../zustand/patient-details.store.ts";
import { usePatientData } from "../zustand/patient.store.ts";
import patientController from "../controllers/patientController.js";
import TagsInput from "../utils/TagsInput.tsx"; // Import the TagsInput component
import {
  FaCheck,
  FaEdit,
  FaPaperPlane,
  FaThumbsUp,
  FaTimes,
} from "react-icons/fa";
import Spinner from "../components/Spinner.tsx";
import axios from "axios";
import { getRootPath } from "../utils/helper.ts";

const ClickedUserDetail = ({ sendMessage }) => {
  const patientDetails = usePatientDetail((state) => state.patient);
  const setPatient = usePatientData((state) => state.actions.setPatient);
  const [isSaved, setIsSaved] = useState(false);
  const [posting, setPosting] = useState(false);

  // Local state for notes and tags
  const [notes, setNotes] = useState(patientDetails.notes);
  const [savedNotes, setSavedNotes] = useState(patientDetails.notes);

  const [tags, setTags] = useState(patientDetails.tags || "");

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleTagsChange = (newTags) => {
    setTags(newTags);
    handleSave(newTags);
  };

  useEffect(() => {
    try {
      const lastNew = JSON.parse(localStorage.getItem("lastNew") ?? "");
      const filteredNewMessages = lastNew.newMessages.filter(
        (msg) => msg.patient_id !== patientDetails._id
      );

      // Update the local storage with the filtered messages
      localStorage.setItem(
        "lastNew",
        JSON.stringify({ ...lastNew, newMessages: filteredNewMessages })
      );
    } catch (e) {
      // Handle error
      console.log("local storage", e);
    }
  }, [patientDetails._id]); // Rerun when patient ID changes

  useEffect(() => {
    // Whenever the patient changes, update the local notes and tags state.
    setNotes(patientDetails.notes || "");
    setTags(patientDetails.tags || ""); // Initialize with existing tags if available
  }, [patientDetails.notes, patientDetails.tags]);

  const handleSave = (newTags?: string) => {
    const updatedPatient = {
      notes: notes ?? "", // Use local state value for notes
      tags: newTags ?? tags, // Use local state value for tags
    };

    setPatient(patientDetails._id, updatedPatient);
    savePatientData(updatedPatient);
  };

  const savePatientData = async (updatedPatient) => {
    const token = localStorage.getItem("jwttoken");
    setPosting(true);
    try {
      await axios.put(
        `${getRootPath()}/patients/${patientDetails._id}`,
        updatedPatient,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token, // Include the token in the headers
          },
        }
      );
      setSavedNotes(notes);
    } catch (error) {
      console.log("Error updating patient data", error);
    }
    setPosting(false);
  };

  const handleEdit = () => {
    localStorage.setItem("lastEditPatient", JSON.stringify(patientDetails));
    window.dispatchEvent(new Event("editPatient"));
  };

  const handleConsent = () => {
    // send consent text, set awaitingConsent in DB if Y returned as the next message then set didConsent true if not clear awaiting consent and leave false.
    const message =
      "Because SMS is an insecure way to communicate, your provider requires your consent to continue.\n\nDO YOU CONSENT TO SMS with Complete Orthopedics? Reply with \n\nI CONSENT\n\nto continue communication.";
    sendMessage(message);
  };
  const [postingAthena, setPostingAthena] = useState(false);
  const [sentToAthena, setSentToAthena] = useState(false);
  const [requestMadeToAthena, setRequestMadeToAthena] = useState(false);

  const handleSendToAthena = () => {
    setRequestMadeToAthena(true);
    setSentToAthena(false);
    setPostingAthena(true);
    patientController(
      { patient_id: patientDetails._id },
      `/athena`,
      "POST",
      (response) => {
        setPostingAthena(false);
        if (response) {
          setSentToAthena(true);
          console.log(
            "Successfully sent history to patient athena doc",
            response
          );
        } else {
          setPostingAthena(false);
          setSentToAthena(false);
          console.log("Failed to send history to athena doc");
        }
      }
    );
  };

  return (
    <main className="user-details-container">
      <div className="patient-info-header">
        <h3>Member Info</h3>
        <button className="action-btn" onClick={handleEdit}>
          <FaEdit />
        </button>
      </div>

      <section className="patient-basic-info">
        <p>
          <span className="bold">Member ID: </span> {patientDetails.patientId}
        </p>
        <p>
          <span className="bold">Member Name: </span>{" "}
          {patientDetails.patientName}
        </p>
        <p>
          <span className="bold">DOB: </span> {patientDetails.DOB}
        </p>
        <p>
          <span className="bold">Mobile Phone: </span>{" "}
          {patientDetails.phoneNumber}{" "}
        </p>
        <p>
          <span className="bold">Home Phone: </span>{" "}
          {patientDetails.homePhoneNumber}{" "}
        </p>
        <p>
          <span className="bold">Did Consent? </span>
          {patientDetails.didConsent ? (
            <span className="text-positive inline-flex items-center gap-1">
              Yes &nbsp; <FaCheck />
            </span>
          ) : (
            <span className="text-negative inline-flex items-center gap-1">
              No &nbsp; <FaTimes />
            </span>
          )}
        </p>
      </section>

      <section className="patient-info-actions">
        {!patientDetails.didConsent && (
          <button
            className="patient-info-action-button"
            onClick={handleConsent}
          >
            <FaThumbsUp /> Request Consent
          </button>
        )}

        <button
          className="patient-info-action-button justify-center"
          onClick={handleSendToAthena}
          disabled={postingAthena}
        >
          {postingAthena ? (
            <Spinner />
          ) : (
            <span className="flex gap-2 justify-center items-center text-center">
              <FaPaperPlane />
              Send to EMR
            </span>
          )}
        </button>
        {requestMadeToAthena && sentToAthena && !postingAthena && (
          <p className="text-xs text-positive text-center mt-1">
            Successfully Sent
          </p>
        )}
        {requestMadeToAthena && !sentToAthena && !postingAthena && (
          <p className="text-xs text-negative text-center mt-1">
            Failed To Send
          </p>
        )}
      </section>

      <div className="mt-3 mb-3">
        <p className="mb-1">Search Tags: </p>
        <TagsInput value={tags} onChange={handleTagsChange} />
      </div>
      <div className="notes-fields">
        <p className="mb-1">Shared Notes: </p>
        <textarea
          className="notes-input"
          value={notes || ""}
          onChange={handleNotesChange}
        ></textarea>
      </div>
      <button
        className="save-notes-btn"
        onClick={() => handleSave()}
        disabled={savedNotes === notes}
      >
        {posting ? <Spinner /> : "Save"}
      </button>
    </main>
  );
};

export default ClickedUserDetail;
