// TagsInput.js
import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import { MdClose } from "react-icons/md";
import "./TagsInput.css"; // Import the CSS file

const useStyles = makeStyles((theme) => ({
  chip: {
    borderRadius: "20px", // Adjust the borderRadius to make them look like pills
  },
}));

const TagsInput = ({ value, onChange }) => {
  const classes = useStyles();
  const [newTag, setNewTag] = useState("");

  const handleAddTag = () => {
    const trimmedNewTag = newTag.trim(); // Trim the new tag
    if (trimmedNewTag !== "") {
      const updatedTags = [
        ...value.split(",").map((tag) => tag.trim()),
        trimmedNewTag,
      ].filter((tag) => tag !== "");
      setNewTag("");
      onChange(updatedTags.join(", ")); // Update the parent component's state as a comma-delimited string
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    const updatedTags = value
      .split(",")
      .map((tag) => tag.trim())
      .filter((tag) => tag !== tagToDelete.trim());
    onChange(updatedTags.join(", ")); // Update the parent component's state as a comma-delimited string
  };

  return (
    <div className="tags-input-container">
      <div className="tags-input-row my-2">
        <input
          type="text"
          placeholder="Add a tag"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          className="tags-input-field"
        />
      </div>
      <button onClick={handleAddTag} className="tags-input-button my-2">
        Add New Tag
      </button>
      <div className="tags-list-container">
        {value
          ?.split(",")
          .map((tag) => tag.trim())
          .filter((tag) => tag !== "")
          .map((tag) => (
            <Chip
              key={tag}
              label={tag}
              onDelete={() => handleDeleteTag(tag)}
              className={`smallChip ${classes.chip}`}
              deleteIcon={<MdClose />}
            />
          ))}
      </div>
    </div>
  );
};

export default TagsInput;
