import { create } from 'zustand';
import { Patient } from './patient.store';

let patient: Patient = null;

export interface PatientDetailState {
    actions: {
        setPatientDetail: (list: Patient) => void;
    };
    patient: Patient;
}

export const usePatientDetail = create<PatientDetailState>((set) => ({
    patient,
    actions: {
        setPatientDetail: (details: Patient) => {
            set((state) => ({
                patient: details
            }));
        },
    },
}));
