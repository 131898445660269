import { getRootPath } from '../utils/helper.ts';

export const settingsController  = async (settingsData, endpoint, method, callback) => {
    try {
      const token = localStorage.getItem('jwttoken');
      const url = `${getRootPath()}settings${endpoint}`; // Update with your backend endpoint for saving settings
      const headers = { 'x-access-token': token, 'Content-Type': 'application/json' };
  
      const options = {
        method: method,
        headers: headers
      };

      // Only add body to the request if method is not GET
      if (method !== 'GET') {
        options.body = JSON.stringify(settingsData);
      }
  
      const response = await fetch(url, options);
  
      const data = await response.json();
  
      if (response.ok) {
        callback(data); // Successfully saved settings
      } else {
        console.error('Error saving settings', data);
        callback(false); // Failed to save settings
      }
    } catch (error) {
      console.error('Unexpected error', error);
      callback(false); // Handle unexpected error
    }
};
export const userSettingsController  = async (settingsData, endpoint, method, callback) => {
  try {
    const token = localStorage.getItem('jwttoken');
    const url = `${getRootPath()}users${endpoint}`; // Update with your backend endpoint for saving settings
    const headers = { 'x-access-token': token, 'Content-Type': 'application/json' };

    const options = {
      method: method,
      headers: headers
    };

    // Only add body to the request if method is not GET
    if (method !== 'GET') {
      options.body = JSON.stringify(settingsData);
    }

    const response = await fetch(url, options);

    const data = await response.json();

    if (response.ok) {
      callback(data); // Successfully saved settings
    } else {
      console.error('Error saving settings', data);
      callback(false); // Failed to save settings
    }
  } catch (error) {
    console.error('Unexpected error', error);
    callback(false); // Handle unexpected error
  }
};