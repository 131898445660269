import React, { useCallback, useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import "./TabMessage.css";
import patientController from "../../controllers/patientController.js";
import ChatBox from "../../ChatBox/ChatBox.tsx";
import { Patient, usePatientData } from "../../zustand/patient.store.ts";
import { getRootPath, randomIntFromInterval } from "../../utils/helper.ts";
import Loader from "../../components/Loader.tsx";
import { debounce } from "lodash";

const TabMessage = ({ tabContent }) => {
  const [activeUser, setActiveUser] = useState<Patient>(null);
  const [activeId, setActiveId] = useState("");
  const [searching, setSearching] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const state = usePatientData((state) => state);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSelectUser = (user: Patient) => {
    setActiveUser(user);
    setActiveId(user._id);
    console.log(showMessage);

    const lastNewString = localStorage.getItem("lastNew") ?? "";
    const lastNew = JSON.parse(lastNewString);
    // Filter out messages from activeUser.phoneNumber
    const filteredMessages = lastNew.newMessages.filter((message) => {
      return message.from !== user.phoneNumber;
    });

    // Update lastNew object with filtered messages
    const updatedLastNew = {
      ...lastNew,
      newMessages: filteredMessages,
    };
    console.log(filteredMessages);
    setNewMessages(filteredMessages);
    // Update the localStorage with the modified lastNew object
    localStorage.setItem("lastNew", JSON.stringify(updatedLastNew));
  };

  const onStorageChange = (event) => {
    try {
      const lastNew = JSON.parse(localStorage.getItem("lastNew") ?? "");
      setNewMessages(lastNew.newMessages);
    } catch (e) {
      // Handle error
      console.log("local storage", e);
    }
  };

  useEffect(() => {
    //clear the extension badge when we click the patients tab
    // chrome.action.setBadgeText({ text: "" });
    let lastNew = {
      since: new Date().toISOString(),
      msgCount: 0,
      newMessages: [],
    };
    localStorage.setItem("lastNew", JSON.stringify(lastNew));

    const lastSeenPatientId = localStorage.getItem("lastSeenPatientId");
    if (lastSeenPatientId && !searching) {
      setSearchTerm(lastSeenPatientId);
      localStorage.removeItem("lastSeenPatientId");
    }
    //chrome.storage.local.get(["lastSeenPatientId"], (result) => {
    //  if (result.lastSeenPatientId && !fetchingPatients) {
    // setSearchTerm(result.lastSeenPatientId);
    // Clean up so it doesn't affect future selections
    //  chrome.storage.local.remove(["lastSeenPatientId"]);
    // }
    //});
  }, []);

  // Add a state for the search term

  const searchPatients = (query) => {
    const userFilter = document.getElementById(
      "chkUserFilter"
    ) as HTMLInputElement;
    let endpoint = "search";
    if (userFilter?.checked) {
      endpoint = "searchByUser";
    }
    if (!searching) {
      setSearching(true);
      // Add search term to the patientController parameters
      //Change

      var myHeaders = new Headers();
      const token = localStorage.getItem("jwttoken") ?? "";
      const organizationId = localStorage.getItem("organizationId");

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-access-token", token);
      const data = JSON.stringify({
        orgId: organizationId,
        query: query,
      });
      fetch(`${getRootPath()}patients/${endpoint}`, {
        method: "POST",
        headers: myHeaders,
        body: data,
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((fetchedPatients) => {
          console.log("hi1");
          console.log(fetchedPatients);
          if (fetchedPatients) {
            state.actions.setPatients(fetchedPatients); // Directly set new patients to state
            if (fetchedPatients.length != 0) {
              handleSelectUser(fetchedPatients[0]);
            }
          }

          console.log("hi2");

          console.log(state.patients);
          setSearching(false);
        });
    }
  };

  const fetchSearch = useCallback(
    debounce((searchTerm) => {
      searchPatients(searchTerm);
    }, 300),
    []
  );

  useEffect(() => {
    console.log("search term ftom use effect: ", searchTerm);
    // if (searchTerm.length > 0) {
    fetchSearch(searchTerm);
    // }
  }, [searchTerm]); // Add searchTerm to the dependency array so that it re-renders every time it changes

  // useEffect(() => {
  //   let endpoint = "/allpatients";
  //   const organizationId = localStorage.getItem("organizationId");

  //   if (!searching) {
  //     setSearching(true);
  //     // Add search term to the patientController parameters

  //     var myHeaders = new Headers();
  //     const token = localStorage.getItem("jwttoken") ?? "";

  //     myHeaders.append("Content-Type", "application/json");
  //     myHeaders.append("x-access-token", token);

  //     fetch(`${getRootPath()}allpatients?orgId=${organizationId}`, {
  //       method: "GET",
  //       headers: myHeaders,

  //       redirect: "follow",
  //     })
  //       .then((response) => response.json())
  //       .then((fetchedPatients) => {
  //         console.log(fetchedPatients);
  //         if (fetchedPatients) {
  //           state.actions.setPatients(fetchedPatients); // Directly set new patients to state
  //           handleSelectUser(fetchedPatients[0]);
  //         }
  //         console.log(state.patients);

  //         setSearching(false);
  //       }); //Change
  //   }
  // }, []);
  const [newMessages, setNewMessages] = useState<any[]>([]);

  useEffect(() => {
    window.addEventListener("storage", onStorageChange);
  }, []);

  return (
    <main className="chatbox-tap-wrapper">
      <div
        className={`tabContent-box-message w-full md:w-1/5 md:flex ${
          showMessage ? "hidden" : "flex"
        }`}
      >
        <header className="chats-header">
          <h1 className="chats-header-title">Chats</h1>
          <div className="chats-search-input">
            <input
              type="text"
              className="search-bar"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="chat-filter-check">
            <label htmlFor="chkUserFilter">
              <input
                type="checkbox"
                id="chkUserFilter"
                onChange={() => searchPatients(searchTerm)}
              />{" "}
              Search by staff name
            </label>
          </div>
        </header>

        {searching ? (
          <Loader />
        ) : (
          <div className="patients-list">
            {state.patients.map((user: Patient) => {
              const patientNewMessagesCount = newMessages
                ? newMessages.filter((msg) => msg.patient_id === user._id)
                    .length
                : 0;
              return (
                <div
                  onClick={() => handleSelectUser({ ...user })}
                  className={
                    activeId === user._id
                      ? `user-message-active`
                      : `user-message`
                  }
                  key={user._id}
                >
                  {patientNewMessagesCount > 0 && (
                    <span className="badge">{patientNewMessagesCount}</span>
                  )}
                  <div className="message-avatar">
                    <FaUser className="avatar-icon" />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h5
                      style={{
                        padding: "0px",
                        margin: "0px",
                        color: activeId === user._id ? "#000000" : "#636262",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "100%",
                      }}
                    >
                      {user.patientName}
                    </h5>

                    <h6
                      style={{
                        padding: "0px",
                        margin: "0px",
                        paddingTop: "5px",
                        color: activeId === user._id ? "#000000" : "#636262",
                      }}
                    >
                      {user.phoneNumber}
                    </h6>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {activeUser && (
        <ChatBox
          showMessage={showMessage}
          setShowMessage={setShowMessage}
          activeUser={activeUser}
        />
      )}
    </main>
  );
};

export default TabMessage;
