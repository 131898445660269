// EmailListTab.js
import React, { useState, useEffect } from "react";
import "./TabEmailSetting.css";
import Spinner from "../../components/Spinner.tsx";
import Loader from "../../components/Loader.tsx";
import { FaPlus } from "react-icons/fa";
import { getRootPath } from "../../utils/helper.ts";

const EmailListTab = ({ tabContent }) => {
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);
  const [removing, setRemoving] = useState(false);
  useEffect(() => {
    // Load emails from the Node.js API when the component mounts
    fetchEmails();
  }, []);

  const fetchEmails = async () => {
    try {
      setLoading(true);
      let organizationId = localStorage.getItem("organizationId") ?? "";

      const response = fetch(`${getRootPath()}/emails/` + organizationId);
      const data = await (await response).json();
      console.log(emails);
      console.log(data.emails);

      setEmails(data.emails);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching emails:", error);
      setLoading(false);
    }
  };

  const saveEmails = async (emailList: string[]) => {
    try {
      let organizationId = localStorage.getItem("organizationId") ?? "";
      const response = await fetch(`${getRootPath()}emails/` + organizationId, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailList),
      });
    } catch (error) {
      console.error("Error saving emails:", error);
    }
  };

  const addEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newEmail.trim()) {
      setPosting(true);
      await saveEmails([...emails, newEmail.trim()]);
      setEmails((prevEmails) => [...prevEmails, newEmail.trim()]);
      setNewEmail("");
      setPosting(false);
    }
  };

  const removeEmail = async (emailToRemove) => {
    setRemoving(true);
    await saveEmails(emails.filter((email) => email !== emailToRemove));
    setEmails((prevEmails) =>
      prevEmails.filter((email) => email !== emailToRemove)
    );
    setRemoving(false);
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <div className="email-container">
        <header>
          <h1>Email List</h1>
          <p className="italics">
            These emails will be notified on new message
          </p>
        </header>
        <form onSubmit={addEmail}>
          <section className="create-email-form">
            <div className="field-control grow-1">
              <input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Enter an email"
                required
              />
            </div>
            <button type="submit" className="add-button" disabled={posting}>
              {posting ? <Spinner /> : <FaPlus />}
            </button>
          </section>
        </form>
        <section className="email-list">
          {emails.map((email) => (
            <div className="email-list-item" key={email}>
              <div className="email-list-item-text">{email}</div>
              <button
                className="email-list-item-btn"
                onClick={() => removeEmail(email)}
                disabled={removing}
              >
                X
              </button>
            </div>
          ))}
        </section>
      </div>
    );
  }
};

export default EmailListTab;
