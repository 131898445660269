import React, { useEffect, useState } from "react";
import { sidebarContent } from "../common/common.tsx";
import "./Sidebar.css";
import TabMessage from "../TabContents/TabMessageContent/TabMessage.tsx";
import TabBookmark from "../TabContents/TabBookmarkContent/TabBookmark.tsx";
import TabNewContact from "../TabContents/TabNewContact/TabNewContact.tsx";
import Settings from "../TabContents/TabSettings/TabSettings.tsx";
import TabBroadcast from "../TabContents/TabBroadcast/TabBroadcast.tsx";
import AddNewUser from "../TabContents/AddNewUser/AddNewUser.tsx";
import EmailListTab from "../TabContents/TabEmailSettings/TabEmailSetting.tsx";
import LinkIcon from "@mui/icons-material/Link";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LogoutIcon from "@mui/icons-material/Logout";
import Logo from "../assets/icon-48.png";
import AddUserTab from "../TabContents/TabAddUser/TabAddUser";
const ids = ["S1", "S2", "S3", "S4", "S6", "S5", "S7", "S9"];

const Sidebar: React.FC<{
  initialTabId?: string;
  setIsLoggedIn: Function;
}> = ({ initialTabId, setIsLoggedIn }) => {
  const [activeId, setActiveId] = useState(initialTabId || "");

  const [version, setVersion] = useState("");
  const [userRole, setuserRole] = useState("");
  useEffect(() => {
    let user = localStorage.getItem("user" || "");
    window.addEventListener("editPatient", onStorageChange);
    if (JSON.parse(user)?.role) {
      const role = JSON.parse(user).role;

      setuserRole(role);
    }
    if (JSON.parse(user)?.role == "admin") {
      const isS9Present = sidebarContent.some((tab) => tab.id === "S9");
      if (!isS9Present) {
        sidebarContent.push({
          id: "S9",
          title: "New User",
          icon: GroupAddIcon,
          subTitle: "New User",
          subIcon: LinkIcon,
        });
      }
    }
  }, []);

  const handleSelectTab = (tab) => {
    if (ids.find((t) => t === tab)) {
      setActiveId(tab);
    }

    console.log(tab);
  };

  const onStorageChange = (event) => {
    const lastEditPatient = localStorage.getItem("lastEditPatient");
    const lastNewPatient = localStorage.getItem("lastNewPatient");
    if (lastNewPatient || lastEditPatient) {
      try {
        if (lastNewPatient) {
          setActiveId("S1");
        }
        if (lastEditPatient) {
          setActiveId("S4");
        }
      } catch (e) {
        console.log("local storage", e);
      }
    }
  };

  useEffect(() => {
    setActiveId("S1");
  }, []);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("jwttoken");
    localStorage.removeItem("user");
    localStorage.removeItem("organizationId");
    setIsLoggedIn(false);
  };

  return (
    <main className={"layout-wrapper"}>
      <div className="sidebar-container">
        <div className="sidebar-logo-container">
          <img src={Logo} />
        </div>

        <div className="tab-container">
          <div className="tabs">
            {sidebarContent.map((tab) => (
              <div
                className={`tab-item ${
                  activeId === tab.id ? "tab-item-active" : ""
                } `}
                key={tab.id}
                style={{ marginBottom: 0 }}
                onClick={() => handleSelectTab(tab.id)}
              >
                <tab.icon className="icon-el" />
              </div>
            ))}
            <button onClick={logout} className="logoutBtn">
              <LogoutIcon />
            </button>
          </div>
        </div>
      </div>

      <div className={"tabContent"}>
        {activeId && activeId === "S1" && (
          <TabMessage
            tabContent={sidebarContent.filter((tab) => tab.id === activeId)}
          />
        )}
        {activeId && activeId === "S4" && (
          <TabNewContact
            tabContent={sidebarContent.filter((tab) => tab.id === activeId)}
            handleCreated={() => {
              setActiveId("S1");
              console.log("created ***");
            }}
          />
        )}
        {activeId && activeId === "S2" && (
          <TabBookmark
            tabContent={sidebarContent.filter((tab) => tab.id === activeId)}
          />
        )}
        {activeId && activeId === "S6" && (
          <TabBroadcast
            tabContent={sidebarContent.filter((tab) => tab.id === activeId)}
          />
        )}
        {activeId && activeId === "S5" && (
          <Settings
            tabContent={sidebarContent.filter((tab) => tab.id === activeId)}
          />
        )}
        {activeId && activeId === "S7" && (
          <EmailListTab
            tabContent={sidebarContent.filter((tab) => tab.id === activeId)}
          />
        )}
        {activeId && activeId === "S9" && userRole === "admin" && (
          <AddNewUser />
        )}
      </div>
      {/* <div className="version-info">{version}</div> */}
    </main>
  );
};

export default Sidebar;
