import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Paper,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Papa from "papaparse";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { broadcastMessage } from "../controllers/messagesController.js";
import "./Broadcast.css";
import Spinner from "../components/Spinner.tsx";
import { getRootPath } from "../utils/helper.ts";
const Broadcast = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [rows, setRows] = useState([]);
  const [message, setMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogIcon, setDialogIcon] = useState(null);
  const [broadcastResults, setBroadcastResults] = useState<any>([]);

  const steps = ["Name the Broadcast", "Upload CSV", "Send Broadcast"];

  const validKeys = ["patientid", "patient name", "patient mobile no", "DOB"];

  const validateCSV = (data) => {
    if (!data) {
      return false;
    }
    const keys = Object.keys(data[0]);
    return validKeys.every((key) => keys.includes(key));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file.type !== "text/csv") {
      alert("Please upload a CSV file.");
      return;
    }
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: function (result) {
        if (!validateCSV(result.data)) {
          alert(
            "The CSV file you uploaded is not in the correct format. Please make sure the file contains the following columns: patientid, patient name, patient mobile no, DOB"
          );
          return;
        }
        setRows(result.data);
      },
    });
  };

  const handleOpenDialog = (content, icon) => {
    setDialogContent(content);
    setDialogIcon(icon);
    setOpenSuccessDialog(true);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setOpenDialog(true);
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const [posting, setPosting] = useState(false);

  const handleBroadcast = async () => {
    setPosting(true);
    const sendRows = rows.map((obj) => ({
      patient_id: obj["patientid"],
      patientName: obj["patient name"],
      phoneNumber: obj["patient mobile no"].toString(),
      DOB: obj["DOB"],
    }));

    var myHeaders = new Headers();
    const token = localStorage.getItem("jwttoken") ?? "";
    const organizationId = localStorage.getItem("organizationId");

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-access-token", token);
    const data = JSON.stringify({
      orgId: organizationId,
      groupName: groupName,
      message: message,
      patients: sendRows,
    });
    fetch(`${getRootPath()}sms/broadcast`, {
      method: "POST",
      headers: myHeaders,
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        setPosting(false);
        console.log(response);
        if (response && response.sent && response.errors) {
          setBroadcastResults(response);
          handleOpenDialog(
            "Broadcast Sent Successfully!",
            <CheckCircleOutlineIcon style={{ color: "green", fontSize: 40 }} />
          );
        } else {
          handleOpenDialog(
            "Something went wrong!",
            <ErrorOutlineIcon style={{ color: "red", fontSize: 40 }} />
          );
        }
      });
  };
  return (
    <div className="broadcast-container">
      <Paper elevation={3}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <section className="mb-20 pt-8">
            <div className="field-control">
              <label htmlFor="">Group name</label>
              <input
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                placeholder="Enter group name"
              />
            </div>
          </section>
        )}
        {activeStep === 1 && (
          <>
            <input
              className="file-input"
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
            />
            <DataGrid
              pagination
              autoPageSize
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
              disableRowSelectionOnClick
              getRowId={(row) => row.patientid}
              rows={rows}
              columns={[
                {
                  field: "patientid",
                  headerName: "Patient ID",
                  sortable: false,
                },
                {
                  field: "patient name",
                  headerName: "Name",
                  width: 200,
                  sortable: false,
                },
                {
                  field: "patient mobile no",
                  headerName: "Phone",
                  width: 200,
                  sortable: false,
                },
                {
                  field: "DOB",
                  headerName: "DOB",
                  width: 200,
                  sortable: false,
                },
              ]}
            />
          </>
        )}
        {activeStep === 2 && (
          <>
            <div className="field-control">
              <label>Enter the message to send to the group.</label>
              <textarea
                className="message-input"
                value={message}
                rows={6}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
          </>
        )}
        <div className="broadcast-buttons">
          <button
            className="broadcast-button broadcast-button-back"
            disabled={activeStep === 0}
            onClick={() => setActiveStep((prev) => prev - 1)}
          >
            Back
          </button>
          <button
            className="broadcast-button broadcast-button-next"
            onClick={handleNext}
            disabled={
              posting ||
              (activeStep === 0 && !groupName) ||
              (activeStep === steps.length - 1 && (!message || message === ""))
                ? true
                : false
            } // Disable button if activeStep is 0 and groupName is empty
          >
            {posting ? (
              <Spinner />
            ) : activeStep === steps.length - 1 ? (
              "Send Broadcast"
            ) : (
              "Next"
            )}
          </button>
        </div>
      </Paper>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Broadcast</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to send a text message to these ${rows.length} patients?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setOpenDialog(false);
              handleBroadcast();
            }}
            color="primary"
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSuccessDialog}
        onClose={() => setOpenSuccessDialog(false)}
      >
        <DialogTitle>
          {dialogIcon} {dialogContent}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogContent === "Broadcast Sent Successfully!" ? (
              <>
                <p>Your message has been sent to all selected patients.</p>
                <DataGrid
                  disableColumnFilter
                  disableColumnSelector
                  disableColumnMenu
                  disableRowSelectionOnClick
                  paginationMode="client"
                  pageSizeOptions={[10000000]}
                  getRowId={(row) => row.patientid}
                  rows={
                    broadcastResults.sent && broadcastResults.errors
                      ? broadcastResults.sent.concat(broadcastResults.errors)
                      : []
                  }
                  columns={[
                    {
                      field: "patientid",
                      headerName: "Patient ID",
                      sortable: false,
                    },
                    {
                      field: "sent",
                      headerName: "Status",
                      sortable: false,
                      renderCell: (params) =>
                        params.value === "successful" ? (
                          <CheckCircleOutlineIcon style={{ color: "green" }} />
                        ) : (
                          <ErrorOutlineIcon style={{ color: "red" }} />
                        ),
                    },
                    { field: "error", headerName: "Error", sortable: false },
                  ]}
                />
              </>
            ) : (
              "An error occurred while sending your broadcast."
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setOpenSuccessDialog(false)}
            className="btn btn-primary"
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Broadcast;
