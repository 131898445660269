import React, { useEffect, useState } from "react";
import "./NewPatient.css";
import patientController from "../controllers/patientController.ts";
import { Patient, usePatientData } from "../zustand/patient.store.ts";
import InputMask from "react-input-mask";
import { getRootPath } from "../utils/helper.ts";
import { Button, Checkbox } from "@mui/material";
import Spinner from "../components/Spinner.tsx";

type Props = {
  handleCreated: (patient) => void;
};
const NewPatient: React.FC<Props> = ({ handleCreated }) => {
  const [patientId, setPatientId] = useState("");
  const [patientName, setPatientName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [homePhoneNumber, setHomePhoneNumber] = useState("");
  const [DOB, setDOB] = useState("");
  const [posting, setPosting] = useState(false);

  const [didConsent, setDidConsent] = useState(false);
  const state = usePatientData((state) => state);

  // Adding states for validation
  const [patientIdError, setPatientIdError] = useState("");
  const [patientNameError, setPatientNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [DOBError, setDOBError] = useState("");

  useEffect(() => {
    let lastEditPatient, lastSeenPatient;
    try {
      lastEditPatient = JSON.parse(
        localStorage.getItem("lastEditPatient") ?? ""
      );
    } catch (e) {
      lastEditPatient = null;
    }
    try {
      lastSeenPatient = JSON.parse(
        localStorage.getItem("lastSeenPatient") ?? ""
      );
    } catch (e) {
      lastSeenPatient = null;
    }

    console.log(lastEditPatient);
    if (lastSeenPatient || lastEditPatient) {
      const {
        patientId,
        patientName,
        phoneNumber,
        homePhoneNumber,
        DOB,
        didConsent,
      } = lastEditPatient ?? lastSeenPatient;
      setPatientId(patientId);
      setPatientName(patientName);
      setPhoneNumber(phoneNumber);
      setHomePhoneNumber(homePhoneNumber);
      setDOB(DOB);
      setDidConsent(didConsent);
      localStorage.removeItem("lastSeenPatient");
      localStorage.removeItem("lastEditPatient");
    }
  }, []);

  const validatePatientId = () => {
    if (!patientId) {
      setPatientIdError("Patient ID is required.");
      return false;
    } else {
      setPatientIdError("");
      return true;
    }
  };

  const validatePatientName = () => {
    if (!patientName) {
      setPatientNameError("Patient Name is required.");
      return false;
    } else {
      setPatientNameError("");
      return true;
    }
  };

  const validatePhoneNumber = () => {
    if (!phoneNumber) {
      setPhoneNumberError("Mobile is required.");
      return false;
    } else {
      setPhoneNumberError("");
      return true;
    }
  };

  const validateDOB = () => {
    if (!DOB) {
      setDOBError("Date Of Birth is required.");
      return false;
    } else {
      setDOBError("");
      return true;
    }
  };

  // useEffect(() => {
  //   validatePatientId();
  // }, [patientId]);

  // useEffect(() => {
  //   validatePatientName();
  // }, [patientName]);

  // useEffect(() => {
  //   validatePhoneNumber();
  // }, [phoneNumber]);

  // useEffect(() => {
  //   validateDOB();
  // }, [DOB]);

  const validateForm = () => {
    return (
      validatePatientId() &&
      validatePatientName() &&
      validatePhoneNumber() &&
      validateDOB()
    );
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!validateForm()) return;
    // Run validation functions to ensure we're up to date

    const orgId = localStorage.getItem("organizationId");
    const patientData = JSON.stringify({
      patientId,
      patientName,
      phoneNumber,
      DOB,
      homePhoneNumber,
      didConsent,
      organizationId: orgId,
    });
    var myHeaders = new Headers();
    const token = localStorage.getItem("jwttoken");

    myHeaders.append("Content-Type", "application/json");
    setPosting(true);
    myHeaders.append("x-access-token", token);
    fetch(`${getRootPath()}patients`, {
      method: "POST",
      headers: myHeaders,
      body: patientData,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((newPatient) => {
        if (newPatient) {
          // Update Zustand Store
          state.actions.addPatient(newPatient);

          // Optionally, tell the TabMessage component to switch to this new patient
          localStorage.setItem("lastSeenPatientId", newPatient.patientId);
          console.log("before sending handleCreated, ", newPatient.patientId);
          handleCreated(newPatient);
          // Clear the input fields
          setPatientId("");
          setPatientName("");
          setPhoneNumber("");
          setHomePhoneNumber("");
          setDOB("");
          setDidConsent(false);
        } else {
          // handle failed form submission
          console.log("Failed to save patient.");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setPosting(false);
      });
  };

  return (
    <div className="new-patient-container">
      <h1 className="mb-5">New Member</h1>
      <form onSubmit={handleSubmit}>
        <div className="field-control">
          <label>
            Member ID <span className="text-negative">*</span>
          </label>
          <input
            type="text"
            value={patientId}
            onChange={(e) => setPatientId(e.target.value)}
            placeholder="######"
          />
          {patientIdError && (
            <span className="field-error">&nbsp;{patientIdError}</span>
          )}
        </div>
        <div className="field-control">
          <label>
            Member Name <span className="text-negative">*</span>
          </label>
          <input
            type="text"
            value={patientName}
            onChange={(e) => setPatientName(e.target.value)}
            placeholder="John Doe"
          />
          {patientNameError && (
            <span className="field-error">&nbsp;{patientNameError}</span>
          )}
        </div>
        <div className="field-control">
          <label>
            Mobile Phone Number <span className="text-negative">*</span>
          </label>
          <InputMask
            mask="+1 999 999-9999"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="+1 123 456-7890"
          >
            {(inputProps: any) => <input {...inputProps} type="text" />}
          </InputMask>
          {phoneNumberError && (
            <span className="field-error">&nbsp;{phoneNumberError}</span>
          )}
        </div>
        <div className="field-control">
          <label>Home Phone Number</label>
          <InputMask
            mask="+1 999 999-9999"
            value={homePhoneNumber}
            onChange={(e) => setHomePhoneNumber(e.target.value)}
            placeholder="+1 123 456-7890"
          >
            {(inputProps: any) => <input {...inputProps} type="text" />}
          </InputMask>
        </div>
        <div className="field-control">
          <label>
            Date of Birth <span className="text-negative">*</span>
          </label>
          <InputMask
            mask="99-99-9999"
            value={DOB}
            onChange={(e) => setDOB(e.target.value)}
            placeholder="MM-DD-YYYY"
          >
            {(inputProps) => <input {...inputProps} type="text" />}
          </InputMask>
          {DOBError && <span className="field-error">&nbsp;{DOBError}</span>}
        </div>
        <div className="field-control">
          <label htmlFor="concent">
            <Checkbox
              id="concent"
              checked={didConsent}
              onChange={(e) => setDidConsent(e.target.checked)}
            />
            Did Consent?
          </label>
        </div>
        <button type="submit" className="btn btn-primary btn-block">
          {posting ? <Spinner /> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default NewPatient;
