import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import './spinner.css';

type Props = {

};


const Spinner: React.FC<Props> = () => {
    return (
      <FaSpinner className="animate-spin" />
    );
};

export default Spinner;
