import { getRootPath } from '../utils/helper.ts';

export const messagesController = (smsData, endpoint, method, callback) => {
  const token = localStorage.getItem('jwttoken');
  const apiEndPoint = endpoint ?? '';

  const headers = {
    'x-access-token': token,
    'Content-Type': 'application/json'
  };  
  
  const data = {
    url: `${getRootPath()}sms${apiEndPoint}`,
    headers: headers,
    method: method,
    body: smsData
  };

  // chrome.runtime.sendMessage({
  //   type: 'xhr',
  //   data: data
  // }, (response) => {
  //   if (response?.data) {
  //     callback(response.data);
  //   } else {
  //     console.log('error performing patient request', response);
  //     callback(false);
  //   }
  // });
};


export const sendMMS = async (smsData, endpoint, callback) => {
  try {
    const token = localStorage.getItem('jwttoken');
    const apiEndPoint = endpoint ?? '';

    const url = `${getRootPath()}sms${apiEndPoint}`;
    const headers = { 'x-access-token': token };

    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: smsData, // Directly use FormData without converting to JSON
    });

    const data = await response.json();

    if (response.ok) {
      callback(data);
    } else {
      console.log('Error performing patient request', data);
      callback(false);
    }
  } catch (error) {
    console.log('Unexpected error', error);
    callback(false);
  }
};

export const broadcastMessage = (broadcastData, callback) => {
  const token = localStorage.getItem('jwttoken');

  const headers = {
    'x-access-token': token,
    'Content-Type': 'application/json',
  };

  const data = {
    url: `${getRootPath()}sms/broadcast`,  // Assuming 'broadcast' is the endpoint for this functionality
    headers: headers,
    method: 'POST',  // Assuming you'll be making a POST request
    body: broadcastData,  
  };

  // chrome.runtime.sendMessage({
  //   type: 'xhr',
  //   data: data,
  // }, (response) => {
  //   if (response?.data) {
  //     callback(response.data);
  //   } else {
  //     console.log('Error performing broadcast request', response);
  //     callback(false);
  //   }
  // });
};

export const sendPrivateLink = (smsData, callback) => {
  const token = localStorage.getItem('jwttoken');

  const headers = {
    'x-access-token': token,
    'Content-Type': 'application/json',
  };

  const data = {
    url: `${getRootPath()}sms/sendPrivate`,
    headers: headers,
    method: 'POST',
    body: JSON.stringify(smsData),  
  };

  // chrome.runtime.sendMessage({
  //   type: 'xhr',
  //   data: data,
  // }, (response) => {
  //   if (response?.data) {
  //     callback(response.data);
  //   } else {
  //     console.log('Error sending private link', response);
  //     callback(false);
  //   }
  // });
};

