import React, { useEffect, useState } from "react";
import "./AddNewUser.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { getRootPath } from "../../utils/helper.ts";
import InputMask from "react-input-mask";
import { FaArrowLeft, FaPlus } from "react-icons/fa";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Loader from "../../components/Loader.tsx";
import Spinner from "../../components/Spinner.tsx";
import axios from "axios";

const AddNewUser: React.FC = () => {
  const [mode, setMode] = useState<"create" | "manage">("manage");

  // //////////////////////////////
  // //////////////////////////////
  // //////////////////////////////

  const ManageUser = () => {
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const getData = async () => {
      var myHeaders = new Headers();
      setLoading(true);
      myHeaders.append("Content-Type", "application/json");
      try {
        let organizationId = localStorage.getItem("organizationId") ?? "";
        let jwttoken = localStorage.getItem("jwttoken") ?? "";
        const response = await fetch(
          `${getRootPath()}admin/getUsers/${organizationId}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": jwttoken,
            },
            method: "GET",
            redirect: "follow",
          }
        );
        const data = await response.json();
        if (data.status) setUsers(data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
      setLoading(false);
    };

    const deleteUser = async (_id: any) => {
      setDeleting(true);
      try {
        await axios.post(`${getRootPath()}admin/deleteUser`, {
          _id: _id,
        });
        getData();
      } catch (error) {
        console.log("error", error);
      }
      setDeleting(false);
    };

    useEffect(() => {
      getData();
    }, []);
    const [users, setUsers] = useState([]);
    return (
      <div className="users-table">
        <div className="flex justify-end items-center mb-5">
          <button onClick={() => setMode("create")} className="btn btn-primary">
            <span>
              <FaPlus />
            </span>
            <span>New User</span>
          </button>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow
                    key={user._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <span className="bold">{user.name}</span>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell align="right">
                      {users.length > 1 && (
                        <button
                          className="delete-user-btn"
                          onClick={() => {
                            deleteUser(user._id);
                          }}
                          disabled={deleting}
                        >
                          {deleting ? (
                            <Spinner />
                          ) : (
                            <DeleteIcon fontSize="small" />
                          )}
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  };

  const CreateUser = () => {
    const [email, setEmail] = useState("");
    const [loginToken, setLoginToken] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [name, setName] = useState("");
    const [role, setRole] = useState("user");
    const [formError, setFormError] = useState("");

    const [posting, setPosting] = useState(false);

    const [errors, setErrors] = useState({
      email: "",
      loginToken: "",
      phoneNumber: "",
      name: "",
      role: "",
    });

    const validateForm = () => {
      const newErrors = {
        email: "",
        loginToken: "",
        phoneNumber: "",
        name: "",
        role: "",
      };
      if (!name) {
        newErrors.name = "Please enter a name";
      }
      if (!email) {
        newErrors.email = "Please enter an email";
      }
      if (!loginToken) {
        newErrors.loginToken = "Please enter password";
      }
      if (!phoneNumber) {
        newErrors.phoneNumber = "Please enter a phone number";
      }
      if (!role) {
        newErrors.role = "Please select a role";
      }
      setErrors(newErrors);
      console.log(newErrors);
      return Object.values(newErrors).every((error) => error === "");
    };

    const handleSubmit = async (e: any) => {
      e.preventDefault();
      if (!validateForm()) return;
      setPosting(true);
      try {
        let organizationId = localStorage.getItem("organizationId") ?? "";
        const raw = JSON.stringify({
          name,
          email,
          loginToken,
          phoneNumber,
          homePhoneNumber: phoneNumber,
          organizationId: organizationId,
          autoSignature: name,
          role,
        });
        const { data } = await axios.post(
          `${getRootPath()}users/register`,
          raw,
          {
            headers: {
              "Content-Type": "application/json",
              "x-register-token": "qYi4dHNu56T@ln!3WCzcQjX9Z*lARC",
            },
          }
        );
        if (data) {
          console.log("wow data", data);
          setMode("manage");
        } else {
          console.log("Failed to save settings");
        }
      } catch (error) {
        setFormError(error.response.data.message);
      }
      setPosting(false);
    };

    return (
      <main>
        <div className="flex justify-end items-center mb-5">
          <button onClick={() => setMode("manage")} className="btn btn-primary">
            <span>
              <FaArrowLeft />
            </span>
            <span>All Users</span>
          </button>
        </div>
        <div className="add-user-form">
          <form onSubmit={handleSubmit}>
            <div className="field-control">
              <label htmlFor="name">Name *</label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && (
                <span className="field-error">&nbsp;{errors.name}</span>
              )}
            </div>
            <div className="field-control">
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <span className="field-error">&nbsp;{errors.email}</span>
              )}
            </div>
            <div className="field-control">
              <label htmlFor="password">Password *</label>
              <input
                type="password"
                id="loginToken"
                name="loginToken"
                value={loginToken}
                onChange={(e) => setLoginToken(e.target.value)}
              />
              {errors.loginToken && (
                <span className="field-error">&nbsp;{errors.loginToken}</span>
              )}
            </div>
            <div className="field-control">
              <label htmlFor="phoneNumber">Phone Number *</label>
              <InputMask
                mask="+1 999 999-9999"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              >
                {(inputProps: any) => <input {...inputProps} type="text" />}
              </InputMask>
              {errors.phoneNumber && (
                <span className="field-error">&nbsp;{errors.phoneNumber}</span>
              )}
            </div>
            <div className="field-control">
              <label htmlFor="phoneNumber">Role *</label>
              <select
                id="mySelect"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="">-- Select --</option>
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
              {errors.role && (
                <span className="field-error">&nbsp;{errors.role}</span>
              )}
            </div>
            {formError && (
              <div className="text-center mb-3 ">
                <p className="text-negative">{formError}</p>
              </div>
            )}
            <button type="submit" className="btn btn-primary btn-block">
              {posting ? <Spinner /> : "Submit"}
            </button>
          </form>
        </div>
      </main>
    );
  };

  return (
    <>
      <main className="users-page-container">
        <header className="user-page-header">
          <h1 className="mb-1">
            {mode === "create" ? "Create User" : "All Users"}
          </h1>
          <p className="italics">
            {mode === "create"
              ? "Fill the form to add a new user to your organization"
              : "Users in your organization"}
            .
          </p>
        </header>
        <section>{mode === "manage" ? <ManageUser /> : <CreateUser />}</section>
      </main>
    </>
  );
};

export default AddNewUser;
