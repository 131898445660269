import React, { useState } from "react";
import Papa from "papaparse";
import Broadcast from "../../Patients/Broadcast.tsx";
const TabBroadcast = ({ tabContent }) => {
  return (
    <div className="tabContent-box-broadcast">
      <header>
        <h1>Broadcast</h1>
        <p className="italics">
          Broadcast message to multiple patients at once
        </p>
      </header>
      <Broadcast />
    </div>
  );
};

export default TabBroadcast;
