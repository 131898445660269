import { getRootPath } from "../utils/helper.ts";

export const templatesController = async (templatesData, endpoint, method) => {
  try {
    const token = localStorage.getItem("jwttoken");
    const url = `${getRootPath()}templates${endpoint}`; // Update with your backend endpoint for saving templates
    const headers = {
      "x-access-token": token,
      "Content-Type": "application/json",
    };

    const options = {
      method: method,
      headers: headers,
    };

    // Only add body to the request if method is not GET
    if (method !== "GET") {
      options.body = JSON.stringify(templatesData);
    }

    const response = await fetch(url, options);
    const data = await response.json();

    if (response.ok) {
      return data; // Successfully saved templates
    } else {
      console.error("Error saving templates", data);
      throw new Error("Failed to save templates"); // Failed to save templates
    }
  } catch (error) {
    console.error("Unexpected error", error);
    throw new Error("Unexpected error"); // Handle unexpected error
  }
};
