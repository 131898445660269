import React, { useState, useEffect } from "react";
import Sidebar from "../New/Sidebar.tsx";
import "./popup.css";
import logo from "../assets/ortho-logo.png";
import { getRootPath } from "../utils/helper.ts";
import InputMask from "react-input-mask";

import Spinner from "../components/Spinner.tsx";

function MainPage() {
  const [userData, setuserData] = useState<any>([]);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [loginToken, setloginToken] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [role, setrole] = useState("admin");
  const [orgError, setOrgError] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgPhoneNumber, setOrgPhoneNumber] = useState("");

  // const [emailVerification, setemailVerification] = useState(false);
  // const [emailVerifiCode, setemailVerifiCode] = useState("");

  const [isCreatingOrg, setIsCreatingOrg] = useState(false);
  const [isCreatingAdmin, setIsCreatingAdmin] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [error, seterror] = useState(false);

  const clearData = () => {
    setname("");
    setemail("");
    setloginToken("");
    setphoneNumber("");
    setOrgName("");
    setOrgPhoneNumber("");
  };

  const handleOrgName = (e) => {
    setOrgName(e.target.value);
  };
  const handleOrgPhoneNumber = (e) => {
    setOrgPhoneNumber(e.target.value);
  };
  const handleEmailChange = (e) => {
    setemail(e.target.value);
  };
  const handleloginTokenChange = (e) => {
    setloginToken(e.target.value);
  };
  // const handleEmailVerifiCodeChange = (e) => {
  //   setemailVerifiCode(e.target.value);
  // };

  const [loginError, setLoginError] = useState("");
  const [postingLogin, setPostingLogin] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginError("");
    setPostingLogin(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email,
      loginToken,
    });

    try {
      const response = await fetch(`${getRootPath()}users/login`, {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      });

      const data = await response.json();

      if (data.auth) {
        localStorage.setItem("organizationId", data.user["organizationId"]);
        localStorage.setItem("token", loginToken);
        localStorage.setItem("jwttoken", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));
        setIsLoggedIn(data.auth);

        setuserData(data.user);
      } else {
        if (data.error) {
          seterror(true);
          setErrorMessage(data.message);
        }
      }
    } catch (error) {
      setLoginError("Invalid email or password");
    }
    setPostingLogin(false);
  };

  // const emailVerificationForm = (e) => {
  //   // if (emailVerifiCode === userData.code) {
  //   e.preventDefault();

  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     email: userData?.email,
  //     code: emailVerifiCode,
  //   });

  //   fetch(`${getRootPath()}/users/emailVerification`, {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response.auth) {
  //         chrome.storage.local.set({
  //           organizationId: response.user["organizationId"],
  //         });
  //         chrome.storage.local.set({ token: loginToken });
  //         chrome.storage.local.set({ jwttoken: response.token });
  //         chrome.storage.local.set({ user: JSON.stringify(response.user) });
  //         localStorage.setItem("token", loginToken);
  //         localStorage.setItem("jwttoken", response.token);
  //         localStorage.setItem("user", JSON.stringify(response.user));
  //         setIsLoggedIn(response.auth);
  //       } else {
  //         console.log(`response ===>> ${JSON.stringify(response)}`);
  //         if (response.error) {
  //           seterror(true);
  //           setErrorMessage(response.message);
  //         }
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };
  const handlePhoneNumberChange = (e: any) => {
    setphoneNumber(e.target.value);
  };
  const handleNameChange = (e: any) => {
    setname(e.target.value);
  };

  const [accountError, setAccountError] = useState("");
  const [postingAccount, setPostingAccount] = useState(false);

  const handleCreateAccount = async (e: any) => {
    e.preventDefault();
    console.log("start");
    setPostingAccount(true);
    setAccountError("");
    try {
      const response = await fetch(`${getRootPath()}users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-register-token": "qYi4dHNu56T@ln!3WCzcQjX9Z*lARC",
        },
        body: JSON.stringify({
          name,
          email,
          loginToken,
          phoneNumber,
          homePhoneNumber: phoneNumber,
          autoSignature: name,
          role: "admin",
        }),
        redirect: "follow",
      });

      const data = await response.json();

      if (data.auth) {
        setIsCreatingAdmin(false);
        setIsCreatingOrg(true);
        localStorage.setItem("jwttoken", data.token);
      } else {
        if (data.message) {
          setAccountError(data.message);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    setPostingAccount(false);
  };

  useEffect(() => {
    setLoading(false);
    if (localStorage.getItem("jwttoken")) {
      setIsLoggedIn(true);
    }
  }, []);

  const goToCreateNewOrg = () => {
    setIsCreatingAdmin(true);
  };

  const [postingOrganization, setPostingOrganization] = useState(false);
  const submitNewOrg = (e) => {
    console.log("userData", userData);
    e.preventDefault();
    if (orgName != "" && orgPhoneNumber != "") {
      setPostingOrganization(true);
      function convertPhoneNumber(input) {
        // Use regular expression to match and capture relevant parts
        var match = input.match(/\+(\d+)\s?\((\d+)\)\s?(\d+)-(\d+)/);

        if (match) {
          // Extract captured groups and format the phone number
          var countryCode = match[1];
          var areaCode = match[2];
          var firstPart = match[3];
          var secondPart = match[4];

          var formattedNumber = `+${countryCode} ${areaCode}-${firstPart}-${secondPart}`;
          return formattedNumber;
        } else {
          // If the input doesn't match the expected format, return the input as is
          return input;
        }
      }
      console.log(convertPhoneNumber(orgPhoneNumber));
      var raw = JSON.stringify({
        name: orgName,
        phoneNumber: convertPhoneNumber(orgPhoneNumber),
      });
      var result = localStorage.getItem("jwttoken") ?? "";
      if (!result) {
        alert("Something went wrong, please try again");
      }
      fetch(`${getRootPath()}organization/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-register-token": "qYi4dHNu56T@ln!3WCzcQjX9Z*lARC",
          "x-access-token": result,
        },
        body: raw,
        redirect: "follow",
      })
        .then((response) => {
          if (!response.ok) {
            console.log(response);
            console.error(
              `Failed to add organization. HTTP status: ${response.status}`
            );
            setOrgError(
              "Organization with the provided phone number already exists."
            );
            setPostingOrganization(false);
            return;
          }
          return response.json();
        })
        .then((response) => {
          if (response) {
            console.log("Successfully Added Organization", response);
            localStorage.setItem("jwttoken", response.token);
            localStorage.setItem("organizationId", response.id);
            localStorage.setItem("user", JSON.stringify(response.user));
            setIsCreatingOrg(false);
            setIsCreatingAdmin(false);
            //verify
            setIsLoggedIn(true);
            setPostingOrganization(false);
          } else {
            console.log("Failed to add organization");
            setPostingOrganization(false);
          }
        })
        .catch((error) => console.log("error", error));
    }
    clearData();
  };

  if (isLoggedIn) {
    return (
      <div className={"popup-container"}>
        <Sidebar initialTabId="S1" setIsLoggedIn={setIsLoggedIn} />
      </div>
    );
  }

  if (isCreatingAdmin) {
    return (
      <>
        {/* <AddNewAdmin setLogin={setIsLoggedIn}></AddNewAdmin> */}
        <div className="org-container">
          <div className="w-full ">
            <div>
              <h1 className="mb-5 text-center">Step 1: Create account</h1>
            </div>
            <form onSubmit={handleCreateAccount}>
              <div className="field-control">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={handleNameChange}
                  required
                />
              </div>
              <div className="field-control">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div className="field-control">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="loginToken"
                  name="loginToken"
                  value={loginToken}
                  onChange={handleloginTokenChange}
                  required
                />
              </div>
              <div className="field-control">
                <label htmlFor="phoneNumber">Phone Number:</label>

                <InputMask
                  mask="+1 999-999-9999"
                  value={phoneNumber}
                  onChange={(e) => setphoneNumber(e.target.value)}
                >
                  {(inputProps: any) => <input {...inputProps} type="text" />}
                </InputMask>
              </div>
              {accountError ? (
                <p className="text-negative mb-3 text-center">{accountError}</p>
              ) : null}
              <div className="flex justify-center">
                <button className="btn btn-primary btn-block" type="submit">
                  {postingAccount ? <Spinner /> : "Create Account"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }

  if (isCreatingOrg) {
    return (
      <div className="org-container">
        <div className="w-full">
          <h2 className="mb-5"> Step 2: New Organization</h2>
          <form onSubmit={submitNewOrg}>
            <div className="field-control">
              <label>Organization Name</label>
              <input
                type="text"
                value={orgName}
                onChange={handleOrgName}
                className="token-input"
                placeholder="Enter Organization Name"
              />
            </div>
            <div className="field-control">
              <label>Organization Phone Number</label>

              <InputMask
                mask="+1 999-999-9999"
                value={orgPhoneNumber}
                onChange={(e) => setOrgPhoneNumber(e.target.value)}
              >
                {(inputProps: any) => <input {...inputProps} type="text" />}
              </InputMask>
              <small>Format:+1 123-45-678</small>
            </div>
            <p className="text-negative text-center mb-3">{orgError}</p>
            <div className="flex justify-center">
              <button type="submit" className="btn btn-primary btn-block">
                {postingOrganization ? <Spinner /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  if (!loading) {
    return (
      <>
        <div className="tabContent-box-newcontact">
          <div className="tabContent-container">
            <img src={logo} alt="Ortho Logo" className="logo" />
            <div>
              <h1 className="mb-5">Login</h1>
            </div>

            {/* {emailVerification ? (
              <form onSubmit={emailVerificationForm}>
                <div className="field-control">
                  <p className="textHeader">
                    {" "}
                    A Code was sent to your email please enter the code so you
                    can Login
                  </p>
                  <label htmlFor="emailVerifiCode">Verification Code</label>
                  <input
                    type="text"
                    id="emailVerifiCode"
                    name="emailVerifiCode"
                    value={emailVerifiCode}
                    onChange={handleEmailVerifiCodeChange}
                    required
                  />
                </div>
                {error ? <p style={{ color: "red" }}>{errorMessage}</p> : null}
                <button type="submit">Submit</button>
              </form>
            ) : ( */}
            <form className="mb-10" onSubmit={handleSubmit}>
              <div className="field-control">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div className="field-control">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="loginToken"
                  name="loginToken"
                  value={loginToken}
                  onChange={handleloginTokenChange}
                  required
                />
              </div>
              {loginError ? (
                <p className="text-negative mb-3 text-center">{loginError}</p>
              ) : null}
              <button className="btn btn-primary btn-block" type="submit">
                {postingLogin ? <Spinner /> : "Login"}
              </button>
            </form>
          </div>
          <div className="signUpSection">
            <p className="mt-3">
              If you do not have an Organization, Sign up here!
            </p>
            <button onClick={goToCreateNewOrg} className="signup-btn">
              Sign Up
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default MainPage;
