// src/background/BackgroundService.js
import React, { useEffect } from 'react';
import { getRootPath } from "../utils/helper.ts";


const BackgroundService = () => {
  useEffect(() => {
    console.log('Simulating background service...');

    const fetchMessagesInterval = setInterval(() => {
      console.log('Fetching messages...');
      const apiIncomingUrl = `${getRootPath()}sms/incomingMessages`;

        const token = localStorage.getItem('jwttoken');
        const lastNewLocal = JSON.parse(localStorage.getItem('lastNew'));
        const orgId = localStorage.getItem('organizationId');

        let lastNew = { since: new Date().toISOString(), msgCount: 0, newMessages: [] };
    
        try {

        } catch (e) {
          console.error(e);
        }
    

        try {
          fetch(apiIncomingUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': token
            },
            body: JSON.stringify({ 'since': lastNewLocal.since, 'orgId' : orgId })
          })      .then((response) => response.json())
          .then((response) => {
    
          if (response.length > 0) {
            console.log('responseData', response);
            const count = response.length;
            console.log('msgCount', count);
            localStorage.setItem( 'lastNew',JSON.stringify({ since: lastNew.since, msgCount: count, newMessages: response }) );
            window.dispatchEvent(new Event("storage"));
           // chrome.action.setBadgeText({ text: count.toString() });
          }
        })
        } catch (error) {
          console.error('error-incoming', error);
        }
          }, 30000); // Adjust the interval as needed

    const handlePatientsInterval = setInterval(() => {
      console.log('Handling patients...');
    //   handleLastSeenPatient();
    }, 30000); // Adjust the interval as needed

    return () => {
      clearInterval(fetchMessagesInterval);
      clearInterval(handlePatientsInterval);
    };
  }, []);

  return null;
};

export default BackgroundService;
