import React, { useState } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import FullScreenIcon from "@mui/icons-material/Fullscreen";
import IconButton from "@mui/material/IconButton";
import { Link as MuiLink } from "@mui/material";
import { FaUser } from "react-icons/fa";
import "./TabBookmark.css";

const TabBookmark = ({ tabContent }) => {
  return (
    <div className="bookmarks-container">
      <header>
        <h1>Bookmarks</h1>
      </header>
      <div className="bookmark-cards">
        <a
          className="bookmark-card"
          href="https://athenanet.athenahealth.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LaunchIcon />
          Medical Health Record
        </a>
      </div>
    </div>
  );
};

export default TabBookmark;
