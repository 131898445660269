import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Tab, Tabs } from "@mui/material";
import { templatesController } from "../controllers/templatesController.js";
import { FaTimes } from "react-icons/fa";
import Spinner from "../components/Spinner.tsx";
import Loader from "../components/Loader.tsx";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TemplateDialog = ({ handleTemplateSelect, handleClose }) => {
  const [newTemplateName, setNewTemplateName] = useState("");
  const [newTemplateMessage, setNewTemplateMessage] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [posting, setPosting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [templatesData, setTemplatesData] = useState([]);

  const handleNewTemplateNameChange = (e) => {
    setNewTemplateName(e.target.value);
  };

  const handleNewTemplateMessageChange = (e) => {
    setNewTemplateMessage(e.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTemplateClicktemplate = (template) => {
    handleTemplateSelect(template); // Pass the template's value to the ChatBox
    handleClose();
  };

  const handleAddNewTemplate = async () => {
    if (newTemplateName.trim() === "" || newTemplateMessage.trim() === "") {
      return;
    }

    setPosting(true);
    try {
      const orgId = localStorage.getItem("organizationId") ?? "";
      const newTemplate = [
        {
          orgId: orgId,
          name: newTemplateName,
          value: newTemplateMessage,
        },
      ];

      const response = await templatesController(newTemplate, "/save", "POST");
      if (response) {
        // Refresh the templates list
        const updatedTemplates = [...templatesData, newTemplate[0]]; // Assuming newTemplate[0] has all needed properties

        setTemplatesData(updatedTemplates);

        setSelectedTab(0);
        // Clear the input fields
        setNewTemplateName("");
        setNewTemplateMessage("");
      } else {
        console.error("Error adding template");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setPosting(false);
  };

  const handleTemplateDelete = async (templateId) => {
    setDeleting(true);
    try {
      const response = await templatesController(
        { templateId },
        `/delete`,
        "POST"
      );
      if (response) {
        // Refresh the templates list
        const updatedTemplates = templatesData.filter(
          (t) => t._id !== templateId
        );
        setTemplatesData(updatedTemplates);
        // Handle successful deletion, e.g., update the UI
        console.log("Template deleted successfully");
      } else {
        console.error("Error deleting template");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setDeleting(false);
  };

  const fetchTemplates = async () => {
    setLoading(true);
    try {
      const method = "GET";
      const orgId = localStorage.getItem("organizationId") ?? "";
      const endpoint = "/templates/" + orgId; // Update with your backend endpoint for fetching templates

      const response = await templatesController({}, endpoint, method);
      if (response) {
        console.log("Templates fetched successfully", response);
        setTemplatesData(response);
        console.log(response); // Assuming response is an array of templates
      } else {
        console.log("Error fetching templates");
      }
      // Rest of your code...
    } catch (error) {
      console.error("Unexpected error", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <Dialog open={true} onClose={handleClose}>
      <button className="close-dialog-btn" onClick={handleClose}>
        <FaTimes />
      </button>
      <DialogTitle>Select a Template</DialogTitle>
      {loading ? (
        <Loader />
      ) : (
        <DialogContent>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Templates" />
            <Tab label="New Template" />
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            <div>
              {templatesData.map((template) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={template._id + "-content"}
                    id={template._id + "-header"}
                    key={template._id}
                  >
                    {template.name}
                  </AccordionSummary>
                  <AccordionDetails>{template.value}</AccordionDetails>
                  <AccordionActions>
                    <button
                      onClick={() => handleTemplateDelete(template._id)}
                      className="btn btn-danger"
                      disabled={deleting}
                    >
                      Delete
                    </button>
                    <button
                      onClick={() => handleTemplateClicktemplate(template)}
                      className="btn btn-primary"
                      disabled={deleting}
                    >
                      Send
                    </button>
                  </AccordionActions>
                </Accordion>
              ))}
            </div>
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <div>
              <div className="field-control">
                <label>
                  Title <span className="text-negative">*</span>
                </label>
                <input
                  type="text"
                  value={newTemplateName}
                  onChange={handleNewTemplateNameChange}
                  placeholder="Enter Template Title"
                />
              </div>
              <div className="field-control">
                <label>
                  Template Message <span className="text-negative">*</span>
                </label>
                <textarea
                  value={newTemplateMessage}
                  onChange={handleNewTemplateMessageChange}
                  placeholder="Enter Template Message"
                />
              </div>
              <div className="flex justify-end">
                <button
                  className="btn btn-primary"
                  onClick={handleAddNewTemplate}
                  disabled={!newTemplateMessage || !newTemplateName}
                >
                  {posting ? <Spinner /> : <span>Save</span>}
                </button>
              </div>
            </div>
          </TabPanel>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default TemplateDialog;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="pt-8">{children}</div>}
    </div>
  );
}
