import { getRootPath } from "../utils/helper.ts";
import axios from "axios";
const patientController = (patientData, endpoint, method, callback) => {
  const token = localStorage.getItem("jwttoken");
  const apiEndPoint = endpoint ?? "";

  axios({
    method: method,
    url: `${getRootPath()}patients${apiEndPoint}`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token, // Include the token in the headers
    },
    data: patientData,
  })
    .then((response) => {
      if (response?.data) {
        callback(response.data);
      } else {
        console.log("error performing patient request", response);
        callback(false);
      }
    })
    .catch((error) => {
      console.log("error performing patient request", error);
      callback(false);
    });
};

export default patientController;
